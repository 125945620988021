import React from "react";
import { BasicTemplate } from "@templates/BasicTemplate";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import { stylesWrapper } from "@theme/commonStyles";
import { graphql, PageProps } from "gatsby";

const StyledWrapper = styled.div`
  padding-top: 200px;
  ${stylesWrapper};
  line-height: 1.6;

  a {
    color: ${({ theme }) => theme.primary};
  }

  p {
    text-align: justify;
  }
`;

const PrivacyPolicy = (props: PageProps<Data>) => {
  const { t } = useTranslation("common");
  const { language } = useI18next();
  let content: string;

  if (language === "en") {
    content = props.data.strapiPrivacyPolicy.contentEN.data.contentEN;
  } else if (language === "de") {
    content = props.data.strapiPrivacyPolicy.contentDE.data.contentDE;
  } else {
    content = props.data.strapiPrivacyPolicy.content.data.content;
  }

  return (
    <BasicTemplate
      title={`${t("privacy-policy")} | ${t("title")}`}
      description={t("description")}
      isAnotherThanIndex
    >
      <StyledWrapper dangerouslySetInnerHTML={{ __html: content }} />
    </BasicTemplate>
  );
};

interface Data {
  strapiPrivacyPolicy: {
    content: {
      data: {
        content: string;
      };
    };
    contentEN: {
      data: {
        contentEN: string;
      };
    };
    contentDE: {
      data: {
        contentDE: string;
      };
    };
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    strapiPrivacyPolicy {
      content {
        data {
          content
        }
      }

      contentEN {
        data {
          contentEN
        }
      }

      contentDE {
        data {
          contentDE
        }
      }
    }
  }
`;

export default PrivacyPolicy;
